import React from 'react';

import './styles.css';

import logo from '../../assets/logo.png';
import instagram from '../../assets/icon_instagram.svg'
import linkedin from '../../assets/icon_linkedin.svg'
import facebook from '../../assets/icon_facebook.svg'

export default function Footer() {
    return (
        <footer className="footer">
            <div className="social">
                <a href="https://rbt.psi.br" target="_blank" rel="noreferrer">
                    <img src={logo} alt="" className="logo" />
                </a>
                <div className="social-buttons">
                    <a href="https://fb.com/rbtinternet" target="_blank" rel="noreferrer"><img src={facebook} alt="" /></a>
                    <a href="https://www.instagram.com/rbt_internet/" target="_blank" rel="noreferrer"><img src={instagram} alt="" /></a>
                    <a href="https://www.linkedin.com/in/rbt-internet-6354271a6/" target="_blank" rel="noreferrer"><img src={linkedin} alt="" /></a>
                </div>
            </div>
            <small>₢ RBT Internet. Todos os direitos reservados.</small>
        </footer>
    )
}
import React from 'react';

import './styles.css';

import Footer from '../../components/Footer';
import arrowDown from '../../assets/arrow_down.svg'

export default function Home() {
    return (
        <>
            <div className="container">
                <div className="arrow bounce">
                    <a href="#content"><img src={arrowDown} alt="" /></a>
                </div>
                <iframe title="test" className="test" src="https://rbt.speedtestcustom.com/" />
                <div id="content" className="content colorized">
                    <h3>O que é o teste de velocidade?</h3>
                    <div>
                        <p>
                            O teste de velocidade tem a finalidade de medir o desempenho da sua conexão de internet.
                            Nele, é possível visualizar informações importantes sobre sua internet, como o ping (latência) e velocidade de download e upload.
                        </p>
                        <p>
                            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M4.16 6.672a.69.69 0 00.513.246h6.667c.368 0 .667-.282.667-.63 0-.347-.3-.628-.667-.628H5.953l2.04-1.924a.61.61 0 00.215-.625.653.653 0 00-.492-.464.692.692 0 00-.663.203L4.027 5.703a.602.602 0 000 .887c.04.033.085.06.133.082zM8 0C3.582 0 0 3.38 0 7.547s3.582 7.546 8 7.546 8-3.38 8-7.546C16 3.38 12.418 0 8 0zM1.333 7.547c0-3.474 2.985-6.29 6.667-6.29s6.667 2.816 6.667 6.29c0 3.468-2.977 6.28-6.654 6.288-3.695 0-6.68-2.815-6.68-6.288zm10.494.874a.686.686 0 00-.514-.245H4.647c-.37 0-.667.282-.667.63 0 .347.298.628.667.628h5.393L8 11.358a.609.609 0 00-.215.624c.06.23.25.41.493.465a.69.69 0 00.662-.203l3.027-2.855a.606.606 0 000-.888.626.626 0 00-.14-.08z" fill="currentColor" fill-rule="evenodd"></path></svg>
                            <strong>PING: </strong>
                                É conhecido também como latência, é importante para usuários que precisam de conexões estáveis, como jogos.
                                Essa informação auxilia na avaliação da estabilidade da conexão, demonstrando o tempo que leva o caminho entre você e o servidor do teste. Quanto mais baixo, melhor.
                        </p>
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 22C6.4 22 2 17.5 2 12S6.4 2 12 2s10 4.4 10 10-4.5 10-10 10z" fill="currentColor" fill-rule="evenodd"></path><path fill="currentColor" fill-rule="evenodd" d="M14.7 14.2c-1.6 0-2.7-.9-3.4-1.8-1.4-2.1-4.3-2.1-4.5.6 0 .4-.3.8-.8.8-.3 0-.8-.3-.8-.8 0-2.9 2.2-3.6 3.8-3.6 1.8 0 2.7.9 3.4 1.9 1.1 1.5 4.2 2.6 4.7-.9 0-.4.3-.8.8-.8.4 0 .8.3.8.8.1 3.5-2.9 3.8-4 3.8z"></path></svg>
                            <strong>JITTER: </strong>
                                Jitter é a variação no atraso de tempo entre pacotes de dados em uma rede. Quanto mais baixo, melhor.
                        </p>
                        <p>
                            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M8 0a8 8 0 100 16A8 8 0 008 0zM1.333 8a6.667 6.667 0 1113.334 0A6.667 6.667 0 011.333 8zm10-.667a.675.675 0 00-.473.194l-2.2 2.2v-5.06a.666.666 0 10-1.333 0v5.06L5.16 7.533a.668.668 0 00-1.12.3.67.67 0 00.173.647l3.334 3.333a.663.663 0 00.946.001l3.334-3.333a.667.667 0 00-.481-1.148h-.014z" fill="currentColor" fill-rule="evenodd"></path></svg>
                            <strong>DOWNLOAD: </strong>
                                O download é a velocidade em que você recebe informações da internet.
                                Este dado mensura a velocidade média da sua internet durante o teste.
                                O valor apresentado será a sua velocidade contratada.
                        </p>
                        <p>
                            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M8 0a8 8 0 100 16A8 8 0 008 0zm0 14.667a6.667 6.667 0 11.013 0H8zm.493-10.48a.663.663 0 00-.946-.001L4.213 7.52a.672.672 0 000 .947c.262.26.686.26.947 0l2.2-2.2v5.066a.666.666 0 101.333 0v-5.06l2.2 2.2a.668.668 0 00.935-.946l-3.335-3.34z" fill="currentColor" fill-rule="evenodd"></path></svg>
                            <strong>UPLOAD: </strong>
                                O upload é a velocidade em que você envia informações para a internet.
                                É utilizado o upload em atividades como realizar um backup, realizar uma publicação na internet ou enviar um vídeo para alguém.
                        </p>
                    </div>
                </div>
                <div className="content">
                    <h3>Como realizar o teste corretamente?</h3>
                    <div>
                        <p>✔️ Para obter um valor preciso, o teste de velocidade deve ser realizado via cabo de rede, evitando perdas por wi-fi.</p>
                        <p>✔️ No momento do teste, certifique-se de que não há outros equipamentos utilizando a internet, feche todos os programas e pause todos downloads e atualizações.</p>
                        <p>✔️ Recomendamos que o teste seja realizado com o servidor da <strong>RBT Internet</strong>, uma vez que outros servidores dependem da qualidade da infraestrutura e do link dos provedores que os hospedam, podendo não refletir a qualidade real de sua conexão.</p>
                        <p>✔️ Para testes acima de 100Mbps, a placa de rede do seu computador precisa ser Gigabit.</p>
                    </div>
                </div>
                <Footer />
            </div>
        </>)
}